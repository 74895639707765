body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 0.5em;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #000000;
}

body::-webkit-scrollbar-horizontal {
  height: 0.5em;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb:horizontal {
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-root.MuiTypography-h4.MuiTypography-alignCenter.MuiDatePickerToolbar-title.css-ddhbay-MuiTypography-root-MuiDatePickerToolbar-title {
  display: none;
}
.MuiTypography-root.MuiTypography-overline.css-14isn7g-MuiTypography-root {
  display: none;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.css-1158rdn-MuiButtonBase-root-MuiButton-root {
  display: none;
}
.MuiTypography-root.MuiTypography-h4.MuiTypography-alignLeft.MuiDatePickerToolbar-title.css-ivlwxw-MuiTypography-root-MuiDatePickerToolbar-title {
  display: none;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-disableElevation.css-1tj3nw6 {
  display: none;
}

.MuiTypography-root.MuiTypography-h4.MuiTypography-alignCenter.MuiDatePickerToolbar-title.css-1fvz66w {
  display: none;
}

.MuiTypography-root.MuiTypography-overline.css-1e04in8 {
  display: none;
}

.gmnoprint {
  display: none;
}

.gm-control-active.gm-fullscreen-control {
  display: none;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-1b2xue5-MuiInputBase-root-MuiOutlinedInput-root {
  border: none;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator.css-va8kp-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-1b2xue5-MuiInputBase-root-MuiOutlinedInput-root:focus {
  border: none;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator.css-1i2xfkx{
  display: none;
}